<template>
  <v-app>
    <toolbar v-if="usuario != null"></toolbar>
    <v-main style="background: rgba(0,23,74,0.1);">
      <router-view/>
    </v-main>
  </v-app>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
* {
  font-family: 'Inter', sans-serif;
}
</style>

<script>
import { mapState } from 'vuex';
import toolbar from './components/toolbar.vue'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components:{
    toolbar
  },
  computed:{
    ...mapState(['usuario'])
  }
};
</script>
