import { initializeApp } from "firebase/app";
import { updateProfile, getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD55qDXrhi7fNjKLOUZkDVBtCABGUmzCyk",
  authDomain: "inmuebles-1749f.firebaseapp.com",
  projectId: "inmuebles-1749f",
  storageBucket: "inmuebles-1749f.appspot.com",
  messagingSenderId: "463162182432",
  appId: "1:463162182432:web:e002181c4acf18fe906545"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export { app, updateProfile, getAuth, signInWithEmailAndPassword, onAuthStateChanged }